import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Image from "next/image";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { verticalStackCss } from "src/theme/spacing";

const backgroundId = "new_landing_page_bg_tcw1sv";

const BackgroundContainer = styled.div`
  position: relative;
  height: calc(100vh - 150px);
  height: calc(100svh - 150px);
  overflow: hidden;
  min-height: 480px;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      height: calc(100vh - 106px);
    `,
  })}
`;

const BackgroundChildrenContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${verticalStackCss.l};
`;

const StyledImage = styled(Image)`
  position: absolute;
  z-index: -1;
  object-fit: cover;
  object-position: top;
`;

export const BackgroundProvider: React.FCC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <BackgroundContainer className={className}>
      <StyledImage alt="" src={backgroundId} fill quality={100} />
      <BackgroundChildrenContainer>{children}</BackgroundChildrenContainer>
    </BackgroundContainer>
  );
};
