import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { Card } from "@components/Card";
import { Link, LinkAppearance } from "@components/Link";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { spacing } from "@every.org/common/src/display/spacing";

import { colorCssVars } from "src/theme/color";
import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";
import { textSizeCss } from "src/theme/text";
import { useStatSigLayer } from "src/utility/abtesting";

const BannerPageSection = styled(PageSection)`
  padding: ${spacing.s} 0 0;
  .banner-card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${spacing.s};
    cursor: pointer;
    background-color: rgba(247, 229, 197, 1);
    &:hover {
      box-shadow: 0px 8px 32px 0px rgba(46, 52, 52, 0.2);
    }
  }
  .banner-text-container {
    display: flex;
    flex-direction: column;
    gap: ${spacing.xs};
  }

  .banner-title {
    ${textSizeCss.m}
  }

  .banner-text {
    max-width: 600px;
  }

  .banner-cta {
    position: relative;
    z-index: 2;
    align-self: flex-end;
    border: none;
    padding: 0;
    color: rgba(131, 96, 33, 1);

    &:hover {
      color: rgba(101, 66, 10, 1);
    }
  }

  .banner-full-size-link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${cssForMediaSize({
    min: MediaSize.LARGE,
    css: css`
      padding: ${spacing.l} 0 0;
      .banner-title {
        ${textSizeCss.l}
      }
      .banner-card {
        flex-direction: row;
        gap: ${spacing.l};
        justify-content: space-between;
        align-items: center;
      }
      .banner-cta {
        align-self: unset;
        color: rgba(131, 96, 33, 1);
        padding: ${spacing.m} ${spacing.l};
        background-color: var(${colorCssVars.background.normal});
        border-width: 2px;
        border-style: solid;
        border-color: rgba(131, 96, 33, 1);
        &:hover {
          border-color: rgba(101, 66, 10, 1);
        }
      }
    `,
  })}

  ${cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      .banner-text-container {
        align-items: center;
        flex-direction: row;
        gap: ${spacing.xl};
      }

      .banner-title {
        flex-shrink: 0;
      }
    `,
  })}
`;

export const PivotalBanner = () => {
  const layer = useStatSigLayer("banner");
  const show = layer?.get<boolean>("show");
  const h1 = layer?.get<string>("h1");
  const main = layer?.get<string>("main");
  const cta = layer?.get<string>("cta");
  const link = layer?.get<string>("link") ?? "";

  if (!show) {
    return null;
  }

  return (
    <BannerPageSection
      className="banner-section"
      contentClassName="banner-section-content"
    >
      <Card className="banner-card">
        <Link
          appearance={LinkAppearance.UNSTYLED}
          className="banner-full-size-link"
          to={link}
          data-tname="logged-out-banner_full-size-link"
        />
        <div className="banner-text-container">
          <h1 className="banner-title">{h1}</h1>
          <p className="banner-text">{main}</p>
        </div>
        <Button
          data-tname="logged-out-banner"
          className="banner-cta"
          contentClassName="banner-cta-content"
          role={ButtonRole.SECONDARY}
          size={ButtonSize.MEDIUM}
          onClick={{
            kind: ButtonTargetKind.LINK,
            to: link,
          }}
        >
          {cta}
        </Button>
      </Card>
    </BannerPageSection>
  );
};
